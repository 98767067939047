<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="bar">
        <div class="title">IP管理</div>
        <div class="rb1" @click="ipadd">
          <img src="@/assets/add.png" class="icon" />
          <div class="text">添加</div>
        </div>  
        <div class="rb" @click="goback">
          <img src="@/assets/fh.png" class="icon" />
          <div class="text">返回</div>
        </div>    
      </div>
      <div class="content">
        <div class="items">
          <div class="item" >
            <div class="box">
              <div class="name i">电脑最大数量：</div>
            </div>
            <div class="box">
              <div class="name i r c">{{ num_computer }}台</div>
            </div>
          </div>
          <div class="empty" v-if="items.length === 0">
            <div>
              <img src="@/assets/empty.png" />
            </div>
            <div class="text">当前无信息</div>
          </div>         
          <div class="item" v-for="item in items" :key="item.id">
            <div class="box">
              <div class="name i">{{ item.terminalip }}</div>
            </div>
            <div class="box">
              <img src="@/assets/del.png" @click="onDelete(item)" class="del active" />
              <img src="@/assets/edit_1.png" @click="onEdit(item)" class="edit active" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, map } from 'lodash-es';
import { Dialog, Toast } from 'vant';
import qs from 'qs';
export default {
  created() {
    this.room_code = this.$route.query.room_code;
    this.type = this.$route.query.type;
    this.unionid = this.$route.query.unionid;
    this.srole = this.$route.query.srole;
    this.num_computer = this.$route.query.num_computer;    
    this.query();
  },
  data() {
    return {
      type: '',
      unionid: '',
      srole: true,
      num_computer: '',
      room_code: '',
      items: [
        // {id:'1', terminalip: '192.168.000.104'},
        // {id:'2', terminalip: '192.168.000.116'},
        // {id:'3', terminalip: '192.168.000.188'}
      ]
    };
  },
  methods: {
    goback() {
      this.$router.replace({path:'/boss/room_list', query:{
        type:this.type,
        unionid:this.unionid,
        srole:this.srole
      }});
    },
    ipadd() {
      if(this.items.length < this.num_computer){
        this.$router.replace({path:'/boss/room_set_ip_add', query:{
          type:this.type,
          unionid:this.unionid,
          srole:this.srole,
          num_computer:this.num_computer,
          room_code:this.room_code
        }});
      }else{
        Toast("IP数量已经超过房间电脑数！");
      }
      
    },
    async query() {
      let { data } = await this.axios.post(
        '/ctid/TldjService/dj_selectroomip',
      qs.stringify({ 
        room_code: this.room_code  
      }));
      if (data.flag === '0') {
        var test = data.data.replace(/-/g, "+").replace(/_/g, "/");
        this.items = JSON.parse(decodeURIComponent(escape(window.atob(test.split('.')[1])))).data;
      } else {
        this.state = false;
        Toast(data.message);
      }
      
    },
    onDelete(item) {
      Dialog.confirm({
        message: '确认是否删除IP[' + item.terminalip + ']?'
      }).then(async () => {
        let { data } = await this.axios.post(
          '/ctid/TldjService/dj_delroomip', 
        qs.stringify({ 
          id: item.id
        }));
        if (data.flag === '0') {
          Toast(data.message);
          this.query();
        } else {
          // Dialog({ message: data.message });
          Toast(data.message);
        }
      });
    },
    onEdit(item) {
      this.$router.replace({path:'/boss/room_set_ip_edit', query:{
        type:this.type,
        unionid:this.unionid,
        srole:this.srole,
        num_computer:this.num_computer,
        room_code:this.room_code,
        terminalip:item.terminalip,
        id:item.id
      }});
    }
    
  }
};
</script>

<style lang="less" scoped>
.bar {
  margin: 48px 40px;
  overflow: hidden;
  .title {
    line-height: 60px;
    color: #fff;
    font-size: 36px;
    float: left;
  }
  
  
  .rb {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    margin-right: 8px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
  .rb1 {
    height: 64px;
    width: 146px;
    float: right;
    overflow: hidden;
    background: #a46fe6;
    border-radius: 38px;
    .icon {
      width: 39px;
      height: 39px;
      margin: 13px;
      display: block;
      float: left;
    }
    
    .text {
      float: left;
      color: #fff;
      font-size: 30px;
      line-height: 64px;
    }
  }
}

.rb2 {
    height: 80px;
    width: 80px;
    overflow: hidden;
    float: right;
    .icon1 {
      width: 50px;
      height: 50px;
      margin: 8px;
      display: block;

    }
  }
.content {
  padding: 10px 0px;
  margin-top: 40px;
}

.xg {
  width: 660px;
  overflow: hidden;
  margin: 0px auto;
  padding: 30px 0px 20px 0px;
}

.items {
  overflow: hidden;
  padding-bottom: 32px;
  min-height: 616px;
  .item {
    margin: 0px 26px;
    padding: 24px 14px 22px;
    overflow: hidden;
    display: flex;
    border-bottom: 2px solid #eee;
  }
  .box {
    flex: 1;
    overflow: hidden;
    .name {
      line-height: 50px;
      overflow: hidden;
      font-size: 32px;
      // color:  #075fc4;
      &.i {
        margin-top: 10px;
      }
      &.r {
        float: right;
        margin-right: 10px;
      }
      &.c {
        color: #075fc4;
      }
      .dxicon {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        vertical-align: middle;
        margin-top: -6px;
      }
    }
    .unames {
      line-height: 40px;
      font-size: 28px;
      margin-top: 8px;
      color: #918e8e;
    }
    .del {
      width: 50px;
      height: 50px;
      display: block;
      float: right;
      margin-top: 5px;
    }
    .edit {
      width: 50px;
      height: 50px;
      display: block;
      float: right;
      margin-top: 5px;
      margin-right: 20px;
    }
    .view {
      margin-top: 18px;
      width: 198px;
      height: 64px;
      border: 2px solid rgba(0, 29, 255, 1);
      border-radius: 44px;
      text-align: center;
      line-height: 64px;
      color: rgba(0, 29, 255, 1);
      font-size: 28px;
      float: right;
      margin-right: 30px;
    }
  }
}
.empty {
  text-align: center;
  margin-top: 212px;
  img {
    width: 150px;
  }
  .text {
    line-height: 40px;
    font-size: 28px;
    margin-top: 20px;
  }
}
</style>
